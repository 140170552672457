.headerCell {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.cell {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.numericColumn {
  text-align: right !important;
}
