.nameCol {
  display: flex;
  align-items: center;
  height: 50px;
}

.thumb {
  text-align: center;
  width: 50px;
  margin-right: 20px;
  margin-left: 5px;
}

.thumb img {
  max-width: 50px;
  max-height: 50px;
}

.name {
  flex: 1;
}

.actions {
  display: flex;
  align-items: center;
}