.returnIcon {
    font-size: 18px;
    color: grey !important;
}

.printerIcon {
    font-size: 18px;
    color: limegreen !important;
}

.isOverdue {
  color: red;
}

.status {
    display: flex;
    align-items: center;
}

.statusIcon {
    display: inline-block;
    margin-right: 8px;
    font-size: 18px;
}

.draftIcon {
    color: grey !important;
}

.expectedIcon {
    color: orange !important;
}

.completedIcon {
    color: limegreen !important;
}