.wrapper {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.menuItem {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 18px;
}

.select {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #FFF;
  transition: border-color 0.5s;
}

.select .icon {
  margin-right: 8px;
}

.select .caret {
  margin-left: 8px;
  color: #CCC;
  transition: color 0.5s;
}

.select:active,
.select:focus {
  border-color: #40A9FF;
}

.select:hover {
  border-color: #CCC;
}
