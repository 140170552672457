.icon {
    margin-right: 8px;
    font-size: 18px;
}

.customerSite {
    display: flex;
    align-items: center;
}

.isOverdue {
  color: red;
}

.exportedIcon {
    margin-right: 8px;
    font-size: 18px;
}

.description {
    display: flex;
    align-items: center;
}

.status {
    display: flex;
    align-items: center;
}

.statusIcon {
    margin-right: 8px;
    font-size: 18px;
}

.statusIcon.proforma {
    display: inline-block;
    color: grey !important;
}

.statusIcon.raised {
    display: inline-block;
    color: limegreen !important;
}

.fulfillment {
    padding-right: 20px;
}

.numericColumn {
  text-align: right !important;
}
