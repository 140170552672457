.titleWrapper {
  display: flex;
}

.title {
  margin-right: 10px;
}

.subtitle {
  color: rgba(0, 0, 0, 0.45);
}
