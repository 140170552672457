.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageFooter {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteButton {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 24px;
}

.imageControls {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter {
  text-align: center;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.dropzone {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 10px;
  background-color: white;
  opacity: 1;
  width: 100%;
  height: 370px;
}

.dropzoneBorder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 4px dashed #1990FF;
}

.dropzoneIcon {
  font-size: 90px;
  line-height: 90px;
  color: #CCC !important;
}

.dropzoneTitle {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}

.dropzoneText {
  color: #999;
  margin-top: 5px;
}
