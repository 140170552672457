.bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid #EEE;
}

.title {
  font-weight: 600;
  font-size: 17px;
}