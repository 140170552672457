.table tbody tr {
  cursor: pointer;
}

.bold {
  font-weight: 600;
}

.numericColumn {
  text-align: right !important;
}
