.isOverdue {
  color: red;
}

.status {
    display: flex;
    align-items: center;
}

.statusIcon {
    margin-right: 8px;
    font-size: 18px;
}

.statusIcon.draft {
    display: inline-block;
    color: grey !important;
}

.statusIcon.expected {
    display: inline-block;
    color: orange !important;
}

.statusIcon.completed {
    display: inline-block;
    color: limegreen !important;
}
