.listItem {
  background-color: white;
  border: 1px solid #e8e8e8;
  padding: 15px !important;
}

.listItemTitle {
  font-size: 13px;
}

.listItemHeader {
  font-size: 16px;
  font-weight: 500;
}

.detail {
  margin-bottom: 15px;
  width: 100px;
  display: inline-block;
}

.detail:nth-last-child(1),
.detail:nth-last-child(2) {
  margin-bottom: 5px;
}

.number {
  font-size: 20px;
  font-weight: 500;
}
