.wrapper :global(.ant-select-disabled),
.wrapper :global(.ant-input-number-disabled) {
  color: rgba(0, 0, 0, 0.65) !important;
}

.checkboxWrapper {
  height: 32px !important;
}

.checkboxWrapper.firstRow {
  margin-top: 29px;
}

.itemNumber {
  margin-left: 8px;
  color: #999;
}