.isAllocation {
  background-color: rgb(153, 225, 253);
}

.isExpectation {
  background-color: rgb(252, 222, 168);
}

.isReading {
  background-color: rgb(177, 226, 177);
}

.numeric {
  text-align: right !important;
}
