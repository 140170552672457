.wrapper {
  width: 350px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 600;
}
