.stockLocation {
  background-color: #f5f5f5 !important;
}

.numeric {
  text-align: right !important;
}

.bold {
  font-weight: 500;
}

.clickable {
  cursor: pointer;
}
