.numericColumn {
  text-align: right !important;
}

.status {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 8px;
    font-size: 18px;
}

.draft {
    display: inline-block;
    color: grey !important;
}

.completed {
    display: inline-block;
    color: limegreen !important;
}