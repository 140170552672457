.draft {
  background-color: #fafafa !important;
}

.speculated {
  background-color: #fdf7ee !important;
}

.expected {
  background-color: #f3fbff !important;
}

.completed {
  background-color: #f3fdf3 !important;
}

/* invoice_no */

.invoiceNo {
  font-weight: 600;
}

/* supplier */

.supplier {
  margin-bottom: 4px;
}

.country {
  display: flex;
  font-size: 13px;
  color: #999999;
}

.flag {
  width: 1.5em !important;
  height: 1.5em !important;
  margin-right: 8px;
}

.contactDetail {
  display: flex;
  margin-bottom: 4px;
}

.contactDetail:last-child {
  margin-bottom: 0px;
}

.contactLabel {
  width: 90px;
}

.contactValue {
  color: #999999;
}

/* date */

.date {
  margin-bottom: 4px;
}

.fromNow {
  font-size: 13px;
}

/* status */

.status {
  display: flex;
  align-items: center;
}

.statusIcon {
  font-size: 18px;
  margin-right: 8px;
}

/* weight */

.weight {
  margin-bottom: 4px;
}

.taxable {
  font-size: 13px;
  color: #999999;
}

.value {
  font-weight: 500;
}

/* tax */

.taxAmount {
  font-weight: 500;
}
