.wrapper {
  background-color: #f5deb344;
}

.header {
  padding: 15px 15px 0px 15px;
  font-weight: 600;
}

.row {
  width: 50%;
  display: flex;
  padding: 5px 15px 5px 15px;
}

.row:last-child {
  padding: 5px 15px 15px 15px;
}

.property {
  width: 50%;
  font-weight: 500;
}

.value {
  width: 50%;
}
