.checkboxWrapper {
  height: 32px !important;
}

.checkboxWrapper.firstRow {
  margin-top: 29px;
}

.itemNumber {
  margin-left: 8px;
  color: #999;
}

.progressWrapper {
  padding-left: 20px;
}

.progressWrapper.firstRow {
  margin-top: 29px;
}