.wrapper {
  height: 315px;
}

.header {
  display: flex;
  align-items: center;
}

.totalValue {
  font-size: 15px;
  font-weight: 500;
}

.content {
  padding: 0px;
}
