.wrapper {
  display: flex;
  align-items: center;
}

.detail {
  margin-left: 32px;
  text-align: right;
  color: #444;
}

.detailContent {
  text-align: right;
  font-weight: 500;
  color: #444;
}
