.icon {
    margin-right: 8px;
    font-size: 18px;
}

.draft {
    display: inline-block;
    color: grey !important;
}

.raised {
    display: inline-block;
    color: limegreen !important;
}

.fulfillment {
    padding-right: 20px;
}

.numericColumn {
  text-align: right !important;
}
