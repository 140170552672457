.wrapper {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EEE;
  padding-left: 16px;
  padding-right: 16px;
}

.title {
  font-size: 17px;
  font-weight: 600;
}
