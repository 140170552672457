.wrapper {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.select {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #FFF;
  transition: border-color 0.5s;
}

.select .caret {
  margin-left: 8px;
  color: #CCC;
  transition: color 0.5s;
}

.select:hover {
  border-color: #CCC;
}

.select:active,
.select:focus {
  border-color: #40A9FF;
}

.reconciled {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default;
}

.icon {
  font-size: 18px;
}

.select .icon {
  margin-right: 8px;
}

.reconciled .icon {
  margin-right: 8px;
}