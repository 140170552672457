.status {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 8px;
    font-size: 18px;
}

.draft {
    display: inline-block;
    color: grey !important;
}

.expected {
    display: inline-block;
    color: orange !important;
}

.completed {
    display: inline-block;
    color: limegreen !important;
}

.inSageIcon {
    font-size: 18px;
    color: limegreen !important;
}