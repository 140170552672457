.wrapper {
  height: 315px;
}

.content {
  padding: 0px;
}

.numericColumn {
  text-align: right !important;
}

.popoverTable th, .popoverTable td {
  padding: 5px;
}
