.wrapper {
  display: flex;
  align-items: center;
  height: 32px;
}

.toggle {
  margin-right: 10px;
  color: #444 !important;
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  margin-top: 2px;
}

.title {
  color: rgba(0, 0, 0, 0.75);
  font-size: 18px;
  font-weight: 500;
}

.search {
  margin-left: 30px !important;
  width: 200px !important;
}