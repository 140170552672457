.filters {
  width: 280px;
}

.box {
  margin-bottom: 12px;
}

.box:last-child {
  margin-bottom: 4px;
}

.label {
  display: block;
  margin-bottom: 4px;
  margin-left: 4px;
}

.title {
  display: flex;
  justify-content: space-between;
}

.rates :global(.ant-list-item:first-child) {
  padding-top: 0px;
}

.rates :global(.ant-list-item:last-child) {
  padding-bottom: 0px;
}

.list {
  width: 250px;
  max-height: 250px;
  overflow: scroll;
  cursor: pointer;
}
