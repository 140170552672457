.tableWrapper {
  margin-top: 20px;
}

.table {
  width: 100%;
  table-layout: fixed;
}

.table td {
  padding: 5px;
  border-right: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  text-align: center;
  font-size: 11px;
}

.table tr:first-child td {
  border-top: 1px solid #CCC;
}

.table td:first-child {
  width: 44px;
  font-weight: bold;
  border-top: none !important;
  border-bottom: none !important;
}

.table tr:first-child td:nth-child(1) {
  color: #1990FF;
}
.table tr:first-child td:nth-child(2) {
  color: #173856;
}