.detail {
  margin-bottom: 15px;
  width: 100px;
  display: inline-block;
}

.detail:nth-last-child(1),
.detail:nth-last-child(2) {
  margin-bottom: 5px;
}

.number {
  font-size: 20px;
  font-weight: 500;
}