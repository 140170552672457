.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
}

.formPanel { 
  flex: 1;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 480px;
  margin: 60px;
}

.form .heading {
  font-size: 28px;
  font-weight: 400;
  color: #444;
  margin-bottom: 50px;
  text-align: center;
}

.form .subheading {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

.form .invalid {
  font-size: 14px;
  font-weight: 600;
  color: #E73737;
  letter-spacing: 0;
  margin-bottom: 24px
}

.form label {
  display: block;
  font-weight: 400;
  font-size: 11px;
  color: #444;
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: default;
}

.form input {
  height: 50px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #CCC;
  outline: none;
  color: #666;
  font-size: 14px;
}

.form input:focus {
  border-color: #1690FF; 
}

.form input::-webkit-input-placeholder {
  color: #CCC;
}

.form input::-moz-placeholder {
  color: #CCC;
}

.form input:-ms-input-placeholder {
  color: #CCC;
}

.form input::-ms-input-placeholder {
  color: #CCC;
}

.form input::placeholder {
  color: #CCC;
}

.form input[name="username"] {
  margin-bottom: 20px;
}

.form input[name="password"] {
  margin-bottom: 30px;
}

.form button[type="submit"] {
  border: none;
  background-color: #1690FF;
  color: white;
  border-radius: 30px;
  width: 180px;
  height: 50px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  outline: none;
  align-self: center;
}

.form button[type="submit"]:hover {
  opacity: 0.9;
}

.form button[type="submit"]:active {
  opacity: 0.95;
}

.form button[type="submit"]:disabled {
  opacity: 0.9;
  cursor: default;
}

.version {
  width: 100%;
  height: 70px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 12px;
}