.list {
  flex: 1;
  overflow-y: scroll;
}

.notification {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #EEE;
  transition: background-color 0.5s;
}

.notification.unread {
  background-color: #F6FAFD;
}

.iconArea {
  
}

.iconCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #1990FF;
}

.icon {
  font-size: 20px;
  color: white !important;
}

.contentArea {
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
}

.title {
  font-weight: bold;
}

.subtitle {
  
}

.subtitle a {
  color: inherit !important;
}

.snippet {
  color: #999;
  font-style: italic;
}

.actions {
  margin-top: 8px;
  display: flex;
}

.action {
  margin-right: 24px;
}

.timestamp {
  color: #999;
  font-size: 12px;
}