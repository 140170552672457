.table th, .table td {
  padding: 5px;
}

.table th:nth-child(2),
.table th:nth-child(3),
.table td:nth-child(2),
.table td:nth-child(3) {
  text-align: right;
}
