.detail {
  margin-left: 32px;
  text-align: right;
  color: #444;
}

.content {
  text-align: right;
  font-weight: 500;
  color: #444;
}
