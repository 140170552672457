:global(.mentions-input) {
  min-height: 80px;
}

:global(.mentions-input) textarea {
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  padding: 8px;
  padding-top: 4px;
}

:global(.mentions-input__suggestions__list) {
  background-color: white;
  border: 1px solid rgba(0,0,0,0.15);
  font-size: 14px;
}

:global(.mentions-input__suggestions__item) {
  padding: 5px 8px;
  border-bottom: 1px solid #FAFAFA;
}

:global(.mentions-input__suggestions__item--focused) {
  background-color: #F6FAFD;
}

.submitButton {
  margin-top: 15px;
}
