.headerRight {
  display: flex;
  align-items: center;
}

.wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.main {
  width: 1px;
  flex: 1;
}

.sidebar {
  width: 250px;
  margin-left: 30px;
}