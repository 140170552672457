.goodsInRecordHeader {
  text-align: center !important;
}

.goodsInRecordActions {
  margin-top: 6px;
  margin-bottom: -4px;
}

.goodsInRecordActionButton {
  margin-left: 5px;
}

.cell {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.numerical {
  text-align: right !important;
}

.editable {
  cursor: pointer;
}

.quantity {
  font-weight: 600;
}

.totals {
  background-color: #fafafa !important;
  font-weight: 600;
}
