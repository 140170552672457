.wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.left {
  width: 1px;
  flex: 1;
}

.right {
  width: 1px;
  flex: 1;
}